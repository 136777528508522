import { Fragment, useRef, useState } from 'react';

import { ResponsiveBar } from '@nivo/bar';
import { Box, FormControl, MenuItem, Select } from '@mui/material';

import TooltipGrafica from '../panel_control/TooltipGrafica';

function Composicion({ absLargoLabel, colores, datos }) {
  const [base, setBase] = useState('Todas');
  const [grafica, setGrafica] = useState('Porcentajes');
  const graficaRef = useRef();

  const colorLetra = (bgColor) => {
    let color = bgColor.charAt(0) === '#' ? bgColor.substring(1, 7) : bgColor;
    let r = parseInt(color.substring(0, 2), 16);
    let g = parseInt(color.substring(2, 4), 16);
    let b = parseInt(color.substring(4, 6), 16);

    let uicolors = [r / 255, g / 255, b / 255];
    let c = uicolors.map((col) => {
      if (col <= 0.03928) {
        return col / 12.92;
      }
      return Math.pow((col + 0.055) / 1.055, 2.4);
    });
    let L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2];

    return L > 0.179 ? '#000' : '#fff';
  };

  const conf = {
    abs: { largo: absLargoLabel ? absLargoLabel : 90 },
    colores: colores
      ? colores
      : [
          '#7BDEA7',
          '#0b4f6c',
          '#105aff',
          '#e1b07e',
          '#404e4d',
          '#5bc0eb',
          '#b2df8a',
          '#ffff99',
          '#fdbf6f',
          '#ff7f00',
          '#cab2d6',
          '#6a3d9a',
          '#b15928',
          '#e31a1c',
          '#fb9a99'
        ]
  };

  const onCambioGrafica = (e) => {
    setGrafica(e.target.value);
    setBase('Todas');
  };

  const referencias = {
    Porcentajes: !Array.isArray(datos.porcentajes) ? datos.porcentajes : undefined,
    'Valores absolutos': !Array.isArray(datos.absolutos) ? datos.absolutos : undefined
  };
  const bases = referencias[grafica]?.bases;

  const cabecera = (
    <>
      <Box sx={{ ...css.cardHeader }}>
        <h2 style={{ display: 'inline-block' }}>
          {referencias[grafica]?.inDev ? 'Composición (EN DESARROLLO)' : 'Composición'}
        </h2>
        <Box>
          {!!bases && process.env.REACT_APP_ENVIRONMENT !== 'testing' && (
            <FormControl sx={{ m: 1, minWidth: 120 }} size='small'>
              <Select value={base} onChange={(val) => setBase(val.target.value)} sx={css.select}>
                {bases.map((_base) => (
                  <MenuItem key={_base} value={_base}>
                    {_base === 'Todas' ? 'Base' : _base}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <FormControl
            sx={{ m: 1, minWidth: 120, float: process.env.NODE_ENV !== 'development' ? 'right' : 'left' }}
            size='small'
          >
            <Select value={grafica} onChange={onCambioGrafica} sx={css.select}>
              {Object.keys(referencias).map((tipo) => (
                <MenuItem key={tipo} value={tipo}>
                  {tipo}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <span style={{ display: 'block', clear: 'both' }} />
        </Box>
      </Box>
    </>
  );

  const ContrastColorLabels = ({ bars }) => {
    return bars.map(({ data, x, y, color, width, height }, i) => {
      return data.formattedValue === '0%' ? (
        <Fragment key={i}></Fragment>
      ) : (
        <g transform={`translate(${x}, ${y})`} key={i}>
          <text
            x={width / 2}
            y={height / 2}
            textAnchor='middle'
            dominantBaseline='central'
            fillOpacity='1'
            style={{
              mixBlendMode: 'normal',
              fontFamily: 'Poppins',
              fontSize: '12px',
              fill: colorLetra(color),
              pointerEvents: 'none',
              fontWeight: 500
            }}
          >
            {data.formattedValue}
          </text>
        </g>
      );
    });
  };

  if (!bases) return cabecera;

  switch (grafica) {
    case 'Porcentajes':
      let porc_filtrado = structuredClone(datos.porcentajes.valores).filter((v) => v.base === base);
      porc_filtrado.forEach((v) => delete v.base);

      let porc_var = structuredClone(datos.porcentajes.variables);
      Object.keys(porc_var).forEach((v, i) => (porc_var[v] = { label: porc_var[v], color: conf.colores[i] }));

      return (
        <>
          {cabecera}
          <>
            <div style={{ height: 76 * porc_filtrado.length + 'px' }} ref={graficaRef}>
              <ResponsiveBar
                data={porc_filtrado}
                keys={Object.keys(porc_var)}
                indexBy='posicion'
                margin={{ top: 0, right: 10, bottom: 0, left: 10 }}
                layout='horizontal'
                padding={0.6}
                enableLabel={false}
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: true }}
                axisTop={null}
                axisRight={null}
                axisBottom={null}
                axisLeft={{
                  tickSize: 0,
                  renderTick: (tick) => {
                    return (
                      <g transform={`translate(${tick.x},${tick.y})`} style={{ opacity: 1 }}>
                        <line
                          x1='0'
                          x2={tick.lineX}
                          y1='0'
                          y2={tick.lineY}
                          style={{ stroke: 'rgb(119, 119, 119)', strokeWidth: '1px' }}
                        ></line>
                        <text
                          dominantBaseline={'text-top'}
                          textAnchor={'start'}
                          transform={`translate(${tick.textX + 3},${tick.textY - 20}) rotate(${tick.rotate})`}
                          style={{
                            fontFamily: 'Poppins',
                            fontSize: '16px',
                            fontWeight: 600,
                            fill: '#202521',
                            color: '#fff'
                          }}
                        >
                          {tick.value}
                        </text>
                      </g>
                    );
                  }
                }}
                enableGridY={false}
                enableGridX={false}
                gridYValues={[10, 20]}
                valueFormat={(valor) => `${Math.round(valor)}%`}
                colors={(bar) => porc_var[bar.id].color}
                theme={{
                  fontFamily: 'Poppins',
                  background: 'transparent',
                  labels: { text: { mixBlendMode: 'color-burn' } }
                }}
                tooltip={({ indexValue, id, formattedValue, color }) => (
                  <TooltipGrafica
                    indexValue={indexValue}
                    id={porc_var[id].label}
                    formattedValue={formattedValue}
                    color={color}
                  />
                )}
                layers={['grid', 'axes', 'bars', ContrastColorLabels, 'markers', 'legends']}
              />
            </div>
            <Box sx={css.cajaLeyenda}>
              {' '}
              {Object.keys(porc_var).map((_var, i) => (
                <div key={i}>
                  <span style={{ backgroundColor: porc_var[_var].color, ...css.leyenda }}></span>{' '}
                  {porc_var[_var].label}
                </div>
              ))}
            </Box>
          </>
        </>
      );
    case 'Valores absolutos':
      let abs_filtrado = structuredClone(datos.absolutos.valores).filter((v) => v.base === base);
      abs_filtrado.forEach((v) => delete v.base);

      let abs_var = structuredClone(datos.absolutos.variables);
      Object.keys(abs_var).forEach((v, i) => (abs_var[v] = { label: abs_var[v], color: conf.colores[i] }));

      const maximo =
        Math.ceil(
          Math.max.apply(
            Math,
            abs_filtrado.map((fila) => {
              let suma = 0;
              Object.keys(fila).forEach((llave) => (llave === 'posicion' ? null : (suma += fila[llave])));
              return suma;
            })
          ) / 100000000
        ) * 100000000;

      return (
        <>
          {cabecera}
          <>
            <div style={{ height: 76 * abs_filtrado.length + 'px' }} ref={graficaRef}>
              <ResponsiveBar
                data={abs_filtrado}
                keys={Object.keys(abs_var)}
                indexBy='posicion'
                margin={{ top: 0, right: 32, bottom: 25, left: conf.abs.largo + 10 }}
                layout='horizontal'
                padding={0.6}
                enableLabel={false}
                maxValue={maximo}
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: true }}
                axisTop={null}
                axisRight={null}
                gridXValues={6}
                axisLeft={{
                  tickSize: 0,
                  renderTick: (tick) => {
                    return (
                      <g transform={`translate(${tick.x},${tick.y})`} style={{ opacity: 1 }}>
                        <line
                          x1='0'
                          x2={tick.lineX}
                          y1='0'
                          y2={tick.lineY}
                          style={{ stroke: 'rgb(119, 119, 119)', strokeWidth: '1px' }}
                        ></line>
                        <foreignObject
                          x={tick.textX - conf.abs.largo}
                          y={tick.textY - 45}
                          width={conf.abs.largo}
                          height='90'
                        >
                          <div
                            style={{
                              height: '100%',
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center'
                            }}
                          >
                            <h6
                              style={{
                                padding: 0,
                                margin: 0,
                                textAlign: 'right',
                                fontSize: '16px',
                                fontWeight: 600
                              }}
                            >
                              {tick.value}
                            </h6>
                          </div>
                        </foreignObject>
                      </g>
                    );
                  }
                }}
                axisBottom={
                  graficaRef?.current?.offsetWidth > 380
                    ? {
                        format: (tick) => tick.toLocaleString('es-CL'),
                        tickValues: graficaRef?.current?.offsetWidth < 560 ? 4 : 6
                      }
                    : { renderTick: () => null }
                }
                enableGridY={false}
                enableGridX={true}
                valueFormat={(valor) => `$ ${Math.round(valor).toLocaleString('es-CL')}`}
                colors={(bar) => abs_var[bar.id].color}
                theme={{
                  fontFamily: 'Poppins',
                  background: 'transparent',
                  labels: { text: { mixBlendMode: 'color-burn' } }
                }}
                tooltip={({ indexValue, id, formattedValue, color }) => (
                  <TooltipGrafica
                    indexValue={indexValue}
                    id={abs_var[id].label}
                    formattedValue={formattedValue}
                    color={color}
                  />
                )}
              />
            </div>
            <Box sx={css.cajaLeyenda}>
              {' '}
              {Object.keys(abs_var).map((_var, i) => (
                <div key={i}>
                  <span style={{ backgroundColor: abs_var[_var].color, ...css.leyenda }}></span>{' '}
                  {abs_var[_var].label}
                </div>
              ))}
            </Box>
          </>
        </>
      );
    default:
      return <div>{cabecera}</div>;
  }
}
export default Composicion;

const css = {
  cardHeader: {
    '& h2': {
      fontSize: { xs: 17, lg: 20 },
      fontWeight: 600,
      margin: 0,
      padding: 0
    }
  },
  select: {
    fontSize: 14,
    backgroundColor: '#e8e8e8',
    borderRadius: '16px',
    '& fieldset': {
      border: 0
    },
    '& .MuiSelect-select': {
      padding: '2px 16px'
    }
  },
  cajaLeyenda: {
    padding: '0 12px',
    '& > div': {
      margin: '0 12px',
      display: 'inline-block',
      fontSize: '14px'
    }
  },
  leyenda: {
    height: '12px',
    width: '12px',
    display: 'inline-block',
    verticalAlign: 'middle',
    borderRadius: '2px'
  }
};
