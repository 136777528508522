import { useRef, useState } from 'react';

import { Box, FormControl, MenuItem, Select, Typography } from '@mui/material';

import { ResponsiveBar } from '@nivo/bar';
import { line } from 'd3-shape';

import TooltipGrafica from '../panel_control/TooltipGrafica';

function Evolucion({ datos }) {
  const [posicion, setPosicion] = useState('Todas');
  const [base, setBase] = useState('Todas');
  const graficaRef = useRef();

  if (!datos) return;

  let posiciones, maximo, gasto;

  if (!Array.isArray(datos)) {
    posiciones = Object.keys(datos.posiciones).map((pos) => {
      return { id: pos, label: datos.posiciones[pos] };
    });

    gasto = datos.gasto.filter((g) => g.posicion === posicion && g.base === base);

    maximo =
      Math.ceil(
        Math.max.apply(
          Math,
          gasto.map((mes) => Math.max(mes.total, mes.anterior))
        ) / 50
      ) * 50;

    maximo = maximo + maximo / 6;
  }

  const cabecera = (
    <>
      <Box sx={css.cardHeader}>
        <h2>
          Evolución Gasto Total Renta Variable{' '}
          {!Array.isArray(datos) && datos?.inDev ? '(EN DESARROLLO)' : ''}
        </h2>
        <Typography sx={css.sectionDesc}>
          Aquí verás el promedio de la distribución de las distintas variables para cada grupo.
        </Typography>
      </Box>
      {!Array.isArray(datos) && !!datos && (
        <Box sx={{ display: 'flex' }}>
          <Box>
            <FormControl sx={{ my: 1, minWidth: 120 }} size='small'>
              <Select value={posicion} onChange={(val) => setPosicion(val.target.value)} sx={css.select}>
                {posiciones.map((pos) => (
                  <MenuItem key={pos.id.trim()} value={pos.id.trim()} sx={css.menuitem}>
                    {pos.id === 'Todas' ? 'Posición' : pos.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {process.env.REACT_APP_ENVIRONMENT !== 'testing' && (
              <FormControl sx={{ my: 1, minWidth: 120 }} size='small'>
                <Select value={base} onChange={(val) => setBase(val.target.value)} sx={css.select}>
                  {datos.bases.map((base) => (
                    <MenuItem key={base.trim()} value={base} sx={css.menuitem}>
                      {base === 'Todas' ? 'Base' : base}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Box>
          <Box sx={css.cajaLeyenda}>
            <div>
              <span style={{ backgroundColor: '#7BDEA7', ...css.leyenda }}></span> {datos.labelActual}
            </div>
            <div>
              <span style={{ backgroundColor: '#056DFA', ...css.leyenda }}></span> {datos.labelAnterior}
            </div>
          </Box>
        </Box>
      )}
    </>
  );

  if (Array.isArray(datos)) return <>{cabecera}</>;

  const Line = ({ bars, xScale, yScale }) => {
    const lineGenerator = line()
      .x((bar) => xScale(bar.data.indexValue) + bar.width / 2)
      .y((bar) => yScale(bar.data.data.anterior ?? 0));

    return (
      <>
        <path d={lineGenerator(bars)} fill='none' stroke='#056DFA' />
        {bars.map((bar) => (
          <circle
            key={bar.key}
            cx={xScale(bar.data.indexValue) + bar.width / 2}
            cy={yScale(bar.data.data.anterior ?? 0)}
            r={4}
            fill='#056DFA'
            stroke={'#056DFA'}
            style={{ pointerEvents: 'none' }}
          />
        ))}
      </>
    );
  };

  return (
    <>
      {cabecera}
      <Box sx={{ minHeight: '100px', maxWidth: '100%', position: 'relative', ...css.graphCont }}>
        <Box sx={{ position: 'relative', height: '100%', zIndex: 1 }} ref={graficaRef}>
          <ResponsiveBar
            data={gasto}
            keys={['total']}
            indexBy='mes'
            margin={{
              top: 10,
              right: -10,
              bottom: graficaRef?.current?.offsetWidth < 376 ? 5 : 25,
              left: 50
            }}
            xScale={{ type: 'point' }}
            maxValue={maximo}
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            colors={['#7BDEA7']}
            axisLeft={{
              format: (tick) => (tick / 1_000_000).toLocaleString('es-CL'),
              tickValues: 7,
              legend: 'M$',
              legendPosition: 'middle',
              legendOffset: -40
            }}
            enableLabel={false}
            enableGridY={false}
            enableGridX={false}
            theme={{ fontFamily: 'Poppins', background: 'transparent' }}
            axisTop={null}
            axisRight={null}
            axisBottom={
              graficaRef?.current?.offsetWidth < 376
                ? { legend: null, renderTick: () => null }
                : { legend: null }
            }
            useMesh={true}
            valueFormat={(valor) => `$ ${Math.round(valor).toLocaleString('es-CL')}`}
            borderRadius={3}
            tooltip={({ indexValue, formattedValue, x, y, data }) => (
              <>
                <TooltipGrafica
                  indexValue={indexValue + ' ' + datos.labelActual}
                  formattedValue={formattedValue}
                  cuadro={'#7BDEA7'}
                />
                <br />
                <TooltipGrafica
                  indexValue={indexValue + ' ' + datos.labelAnterior}
                  formattedValue={`$ ${Math.round(data.anterior ?? 0).toLocaleString('es-CL')}`}
                  width
                  cuadro={'#056DFA'}
                />
              </>
            )}
            layers={['grid', 'axes', 'bars', 'area', Line, 'markers', 'legends']}
          />
        </Box>
      </Box>
    </>
  );
}

const css = {
  card: {
    borderRadius: '16px',
    padding: '16px',
    boxShadow: '0px 2px 11px 0px rgba(69, 81, 76, 0.14)'
  },
  h1: {
    fontSize: { xs: 24, lg: 32 },
    fontWeight: 600,
    margin: 0,
    display: 'block',
    position: 'relative'
  },
  cardHeader: {
    '& h2': {
      fontSize: { xs: 17, lg: 20 },
      fontWeight: 600,
      margin: 0,
      padding: 0
    }
  },
  sectionDesc: {
    fontSize: 14,
    fontWeight: 400
  },
  select: {
    fontSize: 14,
    backgroundColor: '#e8e8e8',
    borderRadius: '16px',
    '& fieldset': {
      border: 0
    },
    '& .MuiSelect-select': {
      padding: '2px 16px'
    }
  },
  menuitem: {
    '&:hover': { backgroundColor: '#f5fcf8' }
  },
  graphCont: {
    pointerEvents: 'none',
    '& g g': { pointerEvents: 'auto' }
  },
  leyenda: {
    height: '12px',
    width: '12px',
    display: 'inline-block',
    verticalAlign: 'middle',
    borderRadius: '2px'
  },
  cajaLeyenda: {
    '&>div': { display: 'inline-block', marginLeft: '12px' },
    textAlign: 'right',
    flexGrow: 1
  }
};

export default Evolucion;
