import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAccion, useObtenerDatos, useProcesos } from '../../../../hooks';

import { ArrowBack, CloudDownloadOutlined } from '@mui/icons-material';
import { Box, Button, CircularProgress, IconButton, Link, Paper, Tooltip, Typography } from '@mui/material';

import InfoBlock from '../comun/InfoBlock';
import TablaArchivos from '../TablaArchivos';

import * as api from '../../../../api';

function ArchivosProceso() {
  const navigate = useNavigate();

  const [errDescarga, setErrDescarga] = useState(null);
  const [subiendo, setSubiendo] = useState(null);
  const [descargando, setDescargando] = useState(false);

  const { grupoModelo, gruposModelos, proceso } = useProcesos();

  const { [`configuracion_proceso_${proceso?.valor?.codigo}`]: configProceso } = useObtenerDatos({
    nombre: `configuracion_proceso_${proceso?.valor?.codigo}`,
    llamadaAPI: api.rv.obtenerConfiguracion,
    parametros: { proceso: proceso?.valor?.codigo },
    condicion: !!proceso?.valor
  });

  const { accionar, procesando, procesado } = useAccion({
    llamadaAPI: api.rv.calcularProceso,
    cacheActualizar: ['procesos', '*' + proceso?.valor?.codigo]
  });

  const onProcesarClick = (evt) => {
    accionar({ proceso: proceso?.valor?.codigo });
  };

  const onDescargaGCSProceso = () => {
    setDescargando(true);
    setErrDescarga(null);

    api.rv
      .descargarGCSProceso({ proceso: proceso?.valor?.codigo })
      .then((res) => res.data)
      .then((data) => {
        const _a = document.createElement('a');
        _a.href = data.url;
        _a.setAttribute('download', `${data.filename}.${data.ext}`);
        _a.click();
        setDescargando(false);
      })
      .catch((err) => {
        if (err.response.status === 404) {
          setErrDescarga('No hay archivos para descargar.');
        }

        setDescargando(false);
      });
  };

  const onConfiguracionClick = (evt) => {
    navigate('/servicios/rv/configuracion', {
      replace: true,
      state: { contexto: { grupoModelo: grupoModelo?.valor, proceso: proceso?.valor?.codigo } }
    });
  };

  const onVerResultadosClick = (evt) => {
    navigate('/servicios/rv/resultados/control-resultados', {
      replace: true,
      state: { contexto: { grupoModelo: grupoModelo?.valor, proceso: proceso?.valor?.codigo } }
    });
  };

  return (
    <Paper sx={{ ...css.card }}>
      <Box sx={{ pt: '20px' }}>
        <Typography component='div' sx={{ ...css.h1, ...css.headerArchivos }}>
          <IconButton
            onClick={() => {
              proceso.setProceso(null);
              navigate('/servicios/rv/proceso-mensual/procesos', {
                replace: true,
                state: { contexto: { grupoModelo: grupoModelo.valor } }
              });
            }}
          >
            <ArrowBack />
          </IconButton>
          {gruposModelos?.length > 1 && <>{`[${grupoModelo?.valor?.toUpperCase()}]`}</>}{' '}
          {proceso?.valor?.nombre}
          <StatusMes estado={proceso?.valor?.estado} />
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
        <Typography sx={css.subSection} component='span'>
          Carga de Archivos
        </Typography>
        <Box sx={css.procesarBtn}>
          {proceso?.valor?.estado !== 'CERRADO' && (
            <Tooltip
              title={
                !configProceso?.confirmada
                  ? 'La configuración no ha sido confirmada'
                  : proceso?.estado !== 'ARCHIVOS CARGADOS'
                  ? 'No hay cambios por procesar'
                  : 'Procesar'
              }
              placement='top'
            >
              <span>
                <Button
                  variant='contained'
                  sx={css.actionBtn}
                  disabled={
                    proceso?.valor?.estado !== 'ARCHIVOS CARGADOS' ||
                    (proceso?.valor?.estado !== 'ARCHIVOS CARGADOS' && configProceso?.confirmada) ||
                    procesando ||
                    subiendo
                  }
                  size='large'
                  onClick={procesando ? undefined : onProcesarClick}
                >
                  {procesando || subiendo ? (
                    <>
                      <CircularProgress size={18} sx={{ m: 1, color: '#539170' }} />
                    </>
                  ) : (
                    <Typography>Procesar</Typography>
                  )}
                </Button>
              </span>
            </Tooltip>
          )}
          {/* {proceso?.valor?.estado !== 'SIN PROCESAR' && (
            <Tooltip title={`Descargar archivos ${proceso?.valor?.nombre}`} placement='top'>
              <IconButton sx={{ background: '#eeeeee' }} onClick={onDescargaGCSProceso}>
                {descargando ? (
                  <CircularProgress size={24} sx={{ padding: '2px', color: '#737373' }} />
                ) : (
                  <CloudDownloadOutlined />
                )}
              </IconButton>
            </Tooltip>
          )} */}
        </Box>
      </Box>
      {configProceso && !configProceso.confirmada && (
        <Box sx={{ padding: '0 24px', mb: 1 }}>
          <InfoBlock tipo={'error'} variant>
            <span style={{ color: '#212121' }}>
              Debe confirmar la{' '}
              <Link
                style={{ color: '#539170', fontWeight: 600, cursor: 'pointer' }}
                onClick={onConfiguracionClick}
              >
                Configuración
              </Link>{' '}
              antes de cargar los archivos.
            </span>
          </InfoBlock>
        </Box>
      )}
      {(procesando || subiendo) && (
        <Box sx={{ padding: '0 24px', mb: 1 }}>
          <InfoBlock tipo='error' variant>
            <span style={{ color: '#212121' }}>Espere un momento. </span>
            <b>No cierre la pestaña.</b>
          </InfoBlock>
        </Box>
      )}
      {procesado && (
        <Box sx={{ padding: '0 24px', mb: 1 }}>
          <InfoBlock tipo='success' sx={{ marginBottom: 1 }}>
            <span>
              Se procesó exitosamente.{' '}
              <Link
                style={{ color: '#539170', fontWeight: 600, cursor: 'pointer' }}
                onClick={onVerResultadosClick}
              >
                Ver Resultados
              </Link>
              .
            </span>
          </InfoBlock>
        </Box>
      )}
      {errDescarga && (
        <Box sx={{ padding: '0 24px', mb: 1 }}>
          <InfoBlock tipo='info' sx={{ marginBottom: 1 }}>
            <span>No hay archivos para descargar.</span>
          </InfoBlock>
        </Box>
      )}
      {!proceso?.valor ? (
        <InfoBlock tipo='cargando'>Cargando Archivos ...</InfoBlock>
      ) : (
        <div style={procesando ? { opacity: 0.7, pointerEvents: 'none' } : {}}>
          <TablaArchivos
            proceso={proceso?.valor}
            configConfirmada={configProceso?.confirmada}
            estadoSubiendo={{ subiendo, setSubiendo }}
          />
        </div>
      )}
    </Paper>
  );
}

const StatusMes = ({ estado }) => {
  let procesado = ['PROCESADO', 'CERRADO'].includes(estado);
  return (
    <span style={{ fontSize: 12, fontWeight: 400, marginLeft: 32, pointerEvents: 'none' }}>
      Estado
      <span
        style={{
          fontWeight: 500,
          backgroundColor: procesado ? '#65b78c' : '#989898',
          display: 'inline-block',
          padding: '1px 14px',
          borderRadius: 16,
          marginLeft: '8px',
          color: 'white'
        }}
      >
        {estado}
      </span>
    </span>
  );
};

// TODO: Limpiar este css
const css = {
  h1: {
    fontSize: { xs: 24, lg: 32 },
    fontWeight: 'bold',
    margin: 0
  },
  subSection: {
    position: 'relative',
    display: 'inline-block',
    fontSize: { xs: 20, lg: 28 },
    fontWeight: 500,
    margin: '0 0px 12px 38px',
    borderBottom: '2px solid #539170'
  },
  sectionDesc: {
    fontSize: { xs: 15, lg: 17 },
    fontWeight: 'light'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    px: 5,
    mt: 1
  },
  headerItem: {
    color: '#5B5B5B',
    fontSize: 14,
    fontWeight: 'medium',
    // width: '30%',
    width: '33%'
  },
  card: {
    borderRadius: '16px',
    '&::after': {
      content: "''",
      display: 'block',
      clear: 'both'
    },
    marginBottom: '64px'
  },
  scroller: {
    maxWidth: { xs: 'calc(100% + 32px)', sm: 'calc(100% + 74px)' },
    overflowX: 'auto',
    margin: { xs: '0 -16px', sm: '0 0px' }
  },
  actionBtn: {
    boxShadow: 'none',
    backgroundColor: 'tentativo.btnGreen',
    color: 'white',
    borderRadius: 20
  },
  loadingBtn: {
    float: 'right',
    boxShadow: 'none',
    backgroundColor: 'rgba(0,0,0,.03)',
    '& p': { color: '#303030' },
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,.03) !important'
    },
    borderRadius: 20
  },
  headerArchivos: {
    flexDirection: 'row',
    alignItems: 'center',
    display: 'flex',
    flexWrap: { xs: 'wrap', md: 'nowrap' }
  },
  procesarBtn: {
    padding: '0 24px 0 0',
    '&::after': {
      content: "''",
      display: 'block',
      clear: 'both'
    },
    flexGrow: '1',
    textAlign: 'right',
    minWidth: '138px'
  }
};

export default ArchivosProceso;
