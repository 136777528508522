import { useEffect, useState } from 'react';
import { useObtenerDatos, useProcesos } from '../../../hooks';

import { ResponsiveBar } from '@nivo/bar';
import { Box, Button, CircularProgress, Grid, Paper, Typography } from '@mui/material';

import AnexoRV from './anexos/AnexoRV';
import SelectorProcesos from './comun/SelectorProceso';
import InfoBlock from './comun/InfoBlock';

import SelectorGrupoModelo from './comun/SelectorGrupoModelo';

import * as api from '../../../api';

const estados = ['CERRADO'];

function MiRentaVariable() {
  const { grupoModelo, gruposModelos, proceso, usuario } = useProcesos({ estados });

  const [rut, setRut] = useState(usuario?.rut);

  const { [`trabajadores_proceso_${proceso?.valor?.codigo}`]: trabajadoresProceso } = useObtenerDatos({
    nombre: `trabajadores_proceso_${proceso?.valor?.codigo}`,
    llamadaAPI: api.rv.obtenerTrabajadores,
    parametros: { proceso: proceso?.valor?.codigo },
    condicion: !!proceso?.valor && ['CERRADO'].includes(proceso?.valor?.estado) && !rut
  });

  useEffect(() => {
    if (!!trabajadoresProceso) {
      const trabajadorRandom = trabajadoresProceso[Math.floor(Math.random() * trabajadoresProceso.length)];
      setRut(trabajadorRandom.id.toString());
    }
  }, [trabajadoresProceso]);

  let errorProceso = { errId: 1, razon: 'Cargando Mi Renta Variable ...', tipo: 'cargando' };

  const [descargando, setDescargando] = useState(false);

  const { [`anexo_${proceso?.valor?.codigo}_trabajador_${rut}`]: anexoTrabajador } = useObtenerDatos({
    nombre: `anexo_${proceso?.valor?.codigo}_trabajador_${rut}`,
    llamadaAPI: api.rv.obtenerAnexoTrabajador,
    parametros: { proceso: proceso?.valor?.codigo, rut: parseInt(rut) },
    condicion: !!proceso?.valor?.codigo && !!rut
  });
  const { [`resumen_${proceso?.valor?.codigo}_trabajador_${rut}`]: resumenTrabajador } = useObtenerDatos({
    nombre: `resumen_${proceso?.valor?.codigo}_trabajador_${rut}`,
    llamadaAPI: api.rv.obtenerResumenTrabajador,
    parametros: { proceso: proceso?.valor?.codigo, rut: parseInt(rut) },
    condicion: !!proceso?.valor?.codigo && !!rut
  });
  const { [`historico_${proceso?.valor?.codigo}_trabajador_${rut}`]: historicoTrabajador } = useObtenerDatos({
    nombre: `historico_${proceso?.valor?.codigo}_trabajador_${rut}`,
    llamadaAPI: api.rv.obtenerHistoricoTrabajador,
    parametros: { proceso: proceso?.valor?.codigo, rut: parseInt(rut) },
    condicion: !!proceso?.valor?.codigo && !!rut
  });

  const onCambioProceso = (codigo) => {
    proceso.setProceso(grupoModelo.procesos.find((p) => p.codigo === codigo));
  };

  const onCambioGrupo = (grupo) => {
    proceso.setProceso(null);
    grupoModelo.setGrupoModelo(grupo);
  };

  const onDescargarAnexo = () => {
    setDescargando(true);
    api.rv
      .descargarAnexoTrabajador({ proceso: proceso.valor.codigo, rut: rut })
      .then((res) => res.data)
      .then((data) => {
        const enlace = `data:${data.type};base64,${data.file}`;

        const _a = document.createElement('a');
        _a.href = enlace;
        _a.setAttribute('download', `${data.filename}.${data.ext}`);
        _a.click();
        setDescargando(false);
      });
  };

  if (!!grupoModelo?.valor) {
    if (grupoModelo?.procesos?.length === 0) {
      errorProceso = {
        errId: -1,
        razon: 'No hay procesos cerrados disponibles',
        tipo: 'info'
      };
    }

    if (!!proceso?.valor) {
      if (proceso.valor.estado !== 'CERRADO') {
        errorProceso = { errId: 10, razon: `Proceso no se ha cerrado aún.`, tipo: 'info' };
      } else if (!anexoTrabajador || !resumenTrabajador || !historicoTrabajador) {
        errorProceso = { errId: 1, razon: 'Cargando Mi Renta Variable ...', tipo: 'cargando' };
      } else {
        errorProceso = { errId: 0 };
      }
    }
  }

  // if (!anexoTrabajador || !resumenTrabajador || !historicoTrabajador)
  //   errorProceso = { errId: 1, razon: 'Cargando Mi Renta Variable ...', tipo: 'cargando' };
  // else if (Array.isArray(resumenTrabajador) || Array.isArray(anexoTrabajador))
  //   errorProceso = { errId: 2, razon: 'Renta Variable no disponible.', tipo: 'info' };

  let historicoTrabajadorMaximos = 0;
  if (!errorProceso.errId) {
    historicoTrabajadorMaximos =
      Math.ceil(
        Math.max.apply(
          Math,
          historicoTrabajador.meses.map((mes) => [mes['Tus datos'], mes['Promedio del nivel']]).flat()
        ) / 100_000
      ) * 100_000;
  }

  return (
    <>
      <Box sx={{ pt: '20px', position: 'relative' }}>
        <Typography sx={{ ...css.h1 }} component={'span'}>
          Mi Renta Variable
          {gruposModelos?.length > 1 && (
            <SelectorGrupoModelo
              onCambioGrupo={onCambioGrupo}
              grupo={grupoModelo.valor}
              grupos={gruposModelos}
            />
          )}
          {grupoModelo?.procesos?.length > 0 && (
            <SelectorProcesos
              onCambioProceso={onCambioProceso}
              proceso={proceso?.valor?.codigo}
              procesos={grupoModelo.procesos}
            />
          )}
        </Typography>
      </Box>
      {errorProceso.errId !== 0 ? (
        <InfoBlock tipo={errorProceso.tipo}>{errorProceso.razon}</InfoBlock>
      ) : (
        <>
          <Grid container spacing={0} alignItems='stretch' direction='row'>
            <Grid container item md={12} lg={6} spacing={0} alignItems='stretch'>
              <Grid
                item
                xs={12}
                lg={7}
                alignItems='stretch'
                container
                flexDirection={{ xs: 'row', lg: 'column' }}
                justifyContent={{ xs: 'space-between', lg: 'stretch !important' }}
              >
                {resumenTrabajador.mayores.map((indicador, i) => {
                  return <Indicador key={i} datos={indicador} grande />;
                })}
              </Grid>
              <Grid
                item
                xs={12}
                lg={5}
                alignItems='stretch'
                container
                flexDirection={{ xs: 'row', lg: 'column' }}
                justifyContent='space-between'
              >
                {resumenTrabajador.menores.map((indicador, i) => {
                  return <Indicador key={i} datos={indicador} />;
                })}
              </Grid>
            </Grid>
            <Grid item md={12} lg={6} alignItems='stretch' container flexDirection='column'>
              <Paper sx={css.card}>
                <Box>
                  <Typography sx={{ ...css.h2, display: 'inline-block', fontSize: 20 }}>
                    Mi RV Histórica
                  </Typography>
                  <Box sx={{ ...css.cajaLeyenda, float: 'right', marginTop: '4px' }}>
                    {' '}
                    {historicoTrabajador.variables.map((_var, i) => (
                      <div key={i}>
                        <span style={{ backgroundColor: colores[i], ...css.leyenda }}></span> {_var}
                      </div>
                    ))}
                  </Box>
                </Box>
                <Box sx={{ height: '200px' }}>
                  <ResponsiveBar
                    data={historicoTrabajador.meses.map((m) => ({
                      ...m,
                      'Tus datos': Math.round(m['Tus datos']),
                      'Promedio del nivel': Math.round(m['Promedio del nivel'])
                    }))}
                    keys={historicoTrabajador.variables}
                    innerPadding={0}
                    padding={0.2}
                    indexBy={historicoTrabajador.indice}
                    margin={{ top: 12, right: 0, bottom: 25, left: 70 }}
                    valueScale={{ type: 'linear' }}
                    indexScale={{ type: 'band', round: true }}
                    axisTop={null}
                    axisRight={null}
                    maxValue={historicoTrabajadorMaximos}
                    gridXValues={6}
                    groupMode='grouped'
                    enableLabel={false}
                    axisLeft={{
                      format: (tick) => tick.toLocaleString('es-CL'),
                      tickValues: 4
                    }}
                    enableGridY={true}
                    enableGridX={false}
                    gridYValues={4}
                    valueFormat={(valor) => `$ ${valor.toLocaleString('es-CL')}`}
                    colors={colores}
                    theme={{
                      fontFamily: 'Poppins',
                      background: 'transparent',
                      labels: { text: { mixBlendMode: 'color-burn' } }
                    }}
                  />
                </Box>
              </Paper>
            </Grid>
          </Grid>
          <Box sx={{ py: '10px', pl: '14px', position: 'relative' }}>
            <Typography sx={{ ...css.h2 }}>
              Anexo Individual Renta Variable <br />
            </Typography>
            {/* <Button variant='contained' sx={{ ...css.actionBtn }} size='large' onClick={onDescargarAnexo}>
              {descargando && <CircularProgress size={18} sx={{ mr: 1, color: '#539170' }} />}
              Descargar Anexo
            </Button>*/}
          </Box>
          {!!anexoTrabajador && <AnexoRV rut={usuario.rut} anexoTrabajador={anexoTrabajador} />}
        </>
      )}
    </>
  );
}

const css = {
  // Usar VW para los textos en los breakpoints centrales
  card: {
    borderRadius: '16px',
    padding: '16px',
    marginY: 0.5,
    flexGrow: 1,
    boxShadow: '0px 2px 11px 0px rgba(69, 81, 76, 0.14)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  h1: {
    fontSize: { xs: 24, lg: 32 },
    fontWeight: 600,
    margin: 0,
    display: 'block',
    position: 'relative'
  },
  h2: {
    fontSize: { xs: 17, lg: 20 },
    fontWeight: 600,
    margin: 0,
    marginBottom: 1
  },
  actionBtn: {
    boxShadow: 'none',
    backgroundColor: 'tentativo.btnGreen',
    color: 'white',
    borderRadius: 20,
    fontSize: { xs: 14, lg: 17 },
    padding: { xs: '4px 20px', lg: 'inherited' },
    position: { xs: 'initial', md: 'absolute' },
    top: 10,
    right: 0
  },
  sectionDesc: {
    fontSize: { xs: 15, lg: '1.15vw', xl: 17 },
    fontWeight: 'light'
  },
  cardBigVal: {
    fontSize: { xs: 24, lg: '2.2vw', xl: 32 },
    fontWeight: 600,
    '& span': {
      fontSize: { xs: 24 },
      position: 'relative',
      top: { xs: 0, lg: -6 }
    }
  },
  cardNote: {
    fontSize: 12,
    fontWeight: 500,
    color: 'rgba(32, 37, 33, 0.66)'
  },
  cardSmallVal: {
    fontSize: { xs: 20, lg: '1.5vw', xl: 24 },
    fontWeight: 600,
    '& span': {
      fontSize: { xs: 20, lg: '1.1vw' },
      position: 'relative',
      top: { xs: 0, lg: -4 }
    }
  },
  cardPorcVal: {
    fontSize: { xs: 20, lg: '1.7vw', xl: 24 },
    fontWeight: 600,
    color: '#539170'
  },
  PorcVal: {
    fontSize: { xs: 20, lg: '1.7vw', xl: 24 },
    verticalAlign: 'middle',
    position: 'relative',
    top: { xs: -2, lg: -2 }
  },
  cajaLeyenda: {
    display: 'inline-block',
    padding: 0,
    '& > div': {
      margin: '0 12px',
      display: 'inline-block',
      fontSize: '14px'
    }
  },
  leyenda: {
    height: '12px',
    width: '12px',
    display: 'inline-block',
    verticalAlign: 'middle',
    borderRadius: '50%'
  }
};

function Indicador({ datos, grande }) {
  const valorRender = formatear(datos);

  if (grande) {
    return (
      <Paper sx={{ ...css.card, flex: { xs: '0 0 calc(50% - 4px)', lg: '1 0 0' }, flexGrow: 1 }}>
        <Typography sx={css.sectionDesc}>{datos.nombre}</Typography>
        <Typography sx={css.cardBigVal}>{valorRender}</Typography>
        {datos.nota && datos.nota !== 'null' ? (
          <Typography sx={css.cardNote}>{datos.nota}</Typography>
        ) : undefined}
      </Paper>
    );
  } else {
    return (
      <Paper
        sx={{
          ...css.card,
          flex: { xs: '0 0 calc(33% - 4px)', lg: 'initial' },
          mx: { xs: 'inherit', lg: 1 }
        }}
      >
        <Typography sx={css.sectionDesc}>{datos.nombre}</Typography>
        <Typography sx={datos.tipo === 'porcentaje' ? css.cardPorcVal : css.cardSmallVal}>
          {valorRender}
        </Typography>
        {datos.nota && datos.nota !== 'null' ? (
          <Typography sx={css.cardNote}>{datos.nota}</Typography>
        ) : undefined}
      </Paper>
    );
  }
}

function formatear(indicador) {
  switch (indicador.tipo) {
    case 'int':
      return <>{`${Math.round(indicador.valor).toLocaleString('es-CL')}`}</>;
    case 'segundos':
      const horas = Math.floor(indicador.valor / 3600);
      const minutos = Math.floor((indicador.valor % 3600) / 60);
      return <>{`${horas.toString().padStart(2, '0')}:${minutos.toString().padStart(2, '0')}`}</>;
    case 'moneda':
      return <>{`${Math.round(indicador.valor).toLocaleString('es-CL')}`}</>;
    case 'porcentaje':
      return <>{`${Math.round(indicador.valor).toLocaleString('es-CL')}%`}</>;
    case 'dias':
      return <>{`${Math.round(indicador.valor).toLocaleString('es-CL')} días`}</>;
    default:
      const _tipo = typeof indicador.valor;
      switch (_tipo) {
        case 'string':
          return <>{indicador.valor}</>;
        case 'number':
          return <>{indicador.valor.toLocaleString('es-CL')}</>;
        case 'boolean':
          return <>{indicador.valor ? 'Sí' : 'No'}</>;
        default:
          return <>{indicador.valor}</>;
      }
  }
}

const colores = ['#7BDEA7', '#0b4f6c', '#105aff', '#e1b07e', '#404e4d', '#5bc0eb'];

export default MiRentaVariable;
